/*
    Configuration index.
    This file should be used as an index (ha!) of JS configuration items, such that there's a single
    place for management of configuration for JS plugins.
*/
import * as map from './maps.config';
import * as slider from './slick.config';
import * as tab from './tabs.config';
import * as menu from './menu.config';
import * as btt from './back-to-top.config';
import * as nav from './nav.config';
import * as video from './video.config';
// import * as slides from './slides.config';

export const maps = map;
export const sliders = slider;
export const tabs = tab;
export const backToTop = btt;
export const menus = menu;
export const navs = nav;
export const videos = video;
// export const slides = slides;
