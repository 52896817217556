export const YouTube = Object({
  trigger: {
    events: 'click touchend',
    selector: '.video-play',
  },
});

export const Modal = Object({
  trigger: {
    events: 'click touchend',
    selector: '.video-modal-close',
  },
  target: {
    selector: '#video-modal',
  },
});
