export const backToTop = Object({ // eslint-disable-line import/prefer-default-export
  trigger: {
    events: 'click touchend',
    selector: '.back-to-top',
    data: null,
  },
  target: {
    selector: 'html, body',
    speed: 'fast',
  },
});
