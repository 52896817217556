export const mobileMenu = Object({
  trigger: 'hamburger',
  target: 'hciMobileNav',
  options: {
    type: 'cover',
  },
});

export const desktopMenu = Object({
  functionParent: 'desktopMenu',
  bindings: {
    firstLevelMenu: {
      trigger: {
        events: 'touchend',
        selector: '#anglicareMainNav li .nav-link',
        data: null,
        function: 'firstLevelMenu',
      },
    },
    secondLevelMenu: {
      trigger: {
        events: 'mouseenter mouseleave touchend',
        selector: '.submenu-link',
        data: null,
        function: 'secondLevelMenu',
      },
    },
    thirdLevelMenu: {
      trigger: {
        events: 'mouseenter mouseleave touchend',
        selector: '.sub-submenu',
        data: null,
        function: 'thirdLevelMenu',
      },
    },
  },
  firstLevelMenu: {
    events: 'touchend',
    selector: 'li',
    className: 'hover',
  },
  secondLevelMenu: {
    events: 'mouseenter touchend',
    selector: {
      trigger: 'a',
      target: '.sub-submenu',
      rel: 'rel',
    },
    className: {
      trigger: 'hover',
      active: 'active fadeIn',
      inactive: 'inactive',
    },
  },
  hoverIntent: {
    over: 'firstLevelMenu',
    out: null,
    selector: {
      wrapper: '.main-navigation-list',
      target: 'a.nav-item',
    },
  },
});
