export const stickyNav = Object({ // eslint-disable-line import/prefer-default-export
  selector: '.headroom',
  options: {
    offset: 245,
    tolerance: 5,
    classes: {
      initial: 'headroom-initial',
      pinned: 'headroom-pinned',
      unpinned: 'headroom-unpinned',
    },
  },
});
