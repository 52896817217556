/* eslint-disable-next-line import/no-unresolved */
// import $ from 'expose-loader?exposes=$!jquery';
import './vendor/jquery.magnific-popup.min';
import './vendor/mlpushmenu';
import './vendor/hoverintent';
import './vendor/nice-select';
import 'jquery-ui';
import '../lib/jquery-accessibleMegaMenu';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';
import 'slick-carousel';
import 'bootstrap';
import '@chenfengyuan/datepicker';
// import '@fortawesome/fontawesome-pro/js/all';
import {
  videos,
  sliders,
  backToTop,
  menus,
} from './config/index';

require('../../scss/hci.scss');

// popper is a pre-requisite for bootstrap 4 dropdowns.
const popper = require('popper.js');

window.Popper = popper;

// classie is a pre-requisite for the mobile menu.
const classie = require('classie');

window.classie = classie;

// Ellipsis is what is used to generate the 'see more' link on content
const Ellipsis = require('ftellipsis');

// YouTube requires it's functions be declared in global scope.
// Super good effort there, guys.
let playerYT = null;

const func = {
  video: {
    onPlayerReady: (event) => {
      event.target.playVideo();
      return false;
    },
    onPlayerStateChange: (/* event */) => {
      // currently unused
    },
    loadVideo: (videoId, isVimeo) => {
      let playerContainer = 'ytplayer';

      if (
        window.isUsingTouch
        && window.matchMedia('(max-width: 700px)').matches
      ) {
        playerContainer = 'ytplayer';
      }

      if (playerYT) {
        // if we have already created a video popup, we need to
        // destroy it before we can load a new one.
        playerYT = null;
      }

      if (isVimeo) {
        // vimeo video
        /* eslint-disable-next-line no-undef */
        playerYT = new Vimeo.Player(playerContainer, {
          id: videoId,
          autoplay: true,
          controls: true,
          events: {
            onStateChange: func.video.onPlayerStateChange,
            onReady: func.video.onPlayerReady,
          },
        });
      } else {
        // youtoube video
        /* eslint-disable-next-line no-undef */
        playerYT = new YT.Player(playerContainer, {
          videoId,
          playerVars: {
            autoplay: 1,
            disablekb: 0,
            html5: 0,
            modestbranding: 1,
            playsinline: 1,
            showinfo: 0,
            fs: 1,
            rel: 0,
            enablejsapi: 1,
          },
          events: {
            onStateChange: func.video.onPlayerStateChange,
            onReady: func.video.onPlayerReady,
          },
        });
      }
    },
  },
};

const bindFunctions = {
  videoPlayer: ({ yt, modal }) => {
    $(document).on(
      yt.trigger.events,
      yt.trigger.selector,
      ({ currentTarget }) => {
        let videoId = null;
        let $target = $(currentTarget);
        const videoModal = $(modal.target.selector);

        if (currentTarget.nodeName !== 'A') {
          $target = $target.parents('a');
        }

        videoId = $target.data('videoid');
        func.video.loadVideo(videoId, $target.data('isvimeo') === 'True');
        videoModal.removeClass('inactive').addClass('active');
        videoModal.modal('show');
        return false;
      },
    );

    $(document).on(
      modal.trigger.events,
      modal.trigger.selector,
      modal.trigger.data,
      () => {
        $(modal.target.selector).modal('hide');
      },
    );

    $(modal.target.selector).on('hidden.bs.modal', () => {
      $(modal.target.selector).removeClass('active').addClass('inactive');
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');
      playerYT.destroy();
    });
  },

  backToTop: (btt) => {
    const { trigger, target } = btt;

    $(document).on(trigger.events, trigger.selector, trigger.data, () => {
      $(target.selector).animate({ scrollTop: 0 }, target.speed);
      return false;
    });
  },

  megaMenu: (options) => {
    const { bindings, functionParent } = options;

    Object.keys(bindings)
      .map(e => bindings[e])
      .forEach((binding) => {
        const { events, selector, function: functionName } = binding.trigger;
        $(document).on(events, selector, (event) => {
          func[functionParent][functionName](event);
        });
      });
  },
};

window.onYouTubeIframeAPIReady = func.video.onYouTubeIframeAPIReady;
const domGetId = selector => document.getElementById(selector);

const initFunctions = {
  videoPlayer: () => {
    const firstScriptTag = document.getElementsByTagName('script')[0];
    const yt = videos.YouTube;
    const modal = videos.Modal;

    const vimeoTag = document.createElement('script');
    vimeoTag.src = 'https://player.vimeo.com/api/player.js';
    firstScriptTag.parentNode.insertBefore(vimeoTag, firstScriptTag);

    const youtubeTag = document.createElement('script');
    youtubeTag.src = 'https://www.youtube.com/iframe_api';
    firstScriptTag.parentNode.insertBefore(youtubeTag, firstScriptTag);

    bindFunctions.videoPlayer({
      yt,
      modal,
    });
  },
  mobileMenu: () => {
    const { mobileMenu } = menus;
    const mobileTrigger = domGetId(mobileMenu.trigger);
    const mobileTarget = domGetId(mobileMenu.target);

    if (mobileTarget && mobileTrigger) {
      (
        () => new MlPushMenu( // eslint-disable-line no-undef
          domGetId(mobileMenu.target),
          domGetId(mobileMenu.trigger),
          mobileMenu.options,
        )
      )();
    }

    $(document).on('click touchend', `#${mobileMenu.trigger}`, (event) => {
      event.stopPropagation();
      $('html body').animate({ scrollTop: 0 }, 0);
      return false;
    });
  },
  seeMore: () => {
    const ellipsis = new Ellipsis($('.content-with-see-more-content').get(0));

    const $wrapper = $('.content-with-see-more-content').eq(0);
    const $content = $wrapper
      .find('.content-with-see-more-content-inner')
      .eq(0);

    $('.header-search-form').on('focus', 'input', () => {
      const $container = $('#mp-pusher ul').first();
      const $scrollTo = $container.find('.my-3.pb-3.text-center');

      $container.animate({
        scrollTop:
          $scrollTo.offset().top
          - $container.offset().top
          + $container.scrollTop(),
      });
      $('body').addClass('menu-search-focus');
    });

    $('.header-search-form').on('blur', 'input', () => {
      $('body').removeClass('menu-search-focus');
    });

    if ($wrapper.height() < $content.height() - 15) {
      ellipsis.calc();
      ellipsis.set();
      // Having run the hiding mechanism, we test for whether we need to
      // actually show the read more link.
      // The extra 10px is some wiggle room for different font rendering
      // (caused by webfonts and fallbacks)
      $('.content-with-see-more-link.content-show').removeClass('d-none');

      // Don't bind the JS to the see more link unless we need to.
      $(document).on(
        'click touchend',
        '.content-with-see-more-link.content-show',
        (e) => {
          e.stopPropagation();
          $('.content-with-see-more-link.content-show').addClass('d-none');
          $('.content-with-see-more-link.content-hide').removeClass('d-none');
          ellipsis.unset();
          $wrapper.removeClass('hide-more');
          return false;
        },
      );

      $(document).on(
        'click touchend',
        '.content-with-see-more-link.content-hide',
        (e) => {
          e.stopPropagation();
          $('.content-with-see-more-link.content-hide').addClass('d-none');
          $('.content-with-see-more-link.content-show').removeClass('d-none');
          $wrapper.addClass('hide-more');
          ellipsis.calc();
          ellipsis.set();
          return false;
        },
      );
    }
  },

  sliders: () => {
    const { sliders: conf } = sliders;
    // Grab our slider configuration from the config file.
    Object.keys(conf)
      .map(e => conf[e])
      .forEach((slider) => {
        // Load the slider with the related configuration
        $(slider.selector).on('init', (evt, slick) => {
          /*
            this function is required since v1.7.1 where it always shows the
            slick dots, even if there's only one slide.
        */
          if (slick.$slides.length < 2) {
            $('.slick-dots').hide();
          }
        });
        $(slider.selector).slick(slider.config);
      });
  },

  // closeSubmenu: () => {
  //   bindFunctions.closeSubmenu();
  // },

  backToTop: () => {
    bindFunctions.backToTop(backToTop.backToTop);
  },
};

window.addEventListener(
  'touchstart',
  function onFirstTouch() {
    // we could use a class
    document.body.classList.add('user-is-touching');

    // or set some global variable
    window.isUsingTouch = true;

    // we only need to know once that a human touched the screen, so we can stop listening now
    window.removeEventListener('touchstart', onFirstTouch, false);
  },
  false,
);

$(document).on(
  'click',
  '.hci--search-trigger, .hci--search-trigger-button',
  (evt) => {
    const event = evt;
    event.cancelBubble = true;
    event.stopPropagation();
    const $nav = $('.navbar--search-field');
    $nav.toggleClass('active inactive');
    const $bar = $nav.find('input');
    const $search = $nav.find('[type="submit"]');
    const $cancel = $nav.find('.hci--search-cancel-button');
    $bar.attr('tabIndex', 0);
    $bar.removeAttr('hidden');
    $bar.attr('aria-hidden', false);
    $search.attr('tabIndex', 0);
    $search.removeAttr('hidden');
    $search.attr('aria-hidden', false);
    $cancel.attr('tabIndex', 0);
    $cancel.removeAttr('hidden');
    $cancel.attr('aria-hidden', false);
  },
);

$(document).on(
  'click',
  '.hci--search-trigger',
  (evt) => {
    const event = evt;
    event.cancelBubble = true;
    event.stopPropagation();
    event.stopImmediatePropagation()
    const $nav = $('.navbar--search-field');
    $nav.toggleClass('active inactive');
    const $bar = $nav.find('input');
    const $search = $nav.find('[type="submit"]');
    const $cancel = $nav.find('.hci--search-cancel-button');
    $bar.attr('tabIndex', 0);
    $bar.removeAttr('hidden');
    $bar.attr('aria-hidden', false);
    $search.attr('tabIndex', 0);
    $search.removeAttr('hidden');
    $search.attr('aria-hidden', false);
    $cancel.attr('tabIndex', 0);
    $cancel.removeAttr('hidden');
    $cancel.attr('aria-hidden', false);
  },
);

$(document).on(
  'click',
  '.hci--search-cancel, .hci--search-cancel-button',
  (evt) => {
    const event = evt;
    event.cancelBubble = true;
    event.stopPropagation();

    const $nav = $(evt.currentTarget).parents('.navbar--search-field');
    const $bar = $nav.find('input');
    const $search = $nav.find('[type="submit"]');
    const $cancel = $nav.find('.hci--search-cancel-button');

    $nav.toggleClass('active inactive');
    $bar.attr('tabIndex', -1);
    $bar.removeAttr('hidden');
    $bar.attr('aria-hidden', true);
    $search.attr('tabIndex', -1);
    $search.removeAttr('hidden');
    $search.attr('aria-hidden', true);
    $cancel.attr('tabIndex', -1);
    $cancel.removeAttr('hidden');
    $cancel.attr('aria-hidden', true);
  },
);

$(document).ready(({ currentTarget: doc }) => {
  $('.popup-gallery').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1], // Will preload 0 - before current, and 1 after the current image
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      titleSrc: function xyz(item) {
        return item.el.attr('title');
      },
    },
  });

  Object.keys(initFunctions)
    .map(e => initFunctions[e])
    .forEach((functionItem) => {
      functionItem.apply(doc, Array.prototype.slice.call(1));
    });

  $('select').niceSelect();

  if ($('.date-information--wrapper').length > 0) {
    $('.date-information--wrapper')
      .removeClass('d-none')
      .insertAfter($('.page-title'));
  }

  $('.datepickerfield').each((idx, el) => {
    const $el = $(el);
    const $label = $(`label[for='${el.name}']`);
    $el
      .addClass('col-10 flex-shrink-1')
      .attr('placeholder', `${$label.get(0).innerText}`)
      .wrap('<div class="input-group row no-gutters flex-row flex-nowrap">')
      .after(
        `<button type='button' id='trigger_${el.id}' class='col-2 btn btn-datepicker docs-datepicker-trigger'><i class='far fa-calendar' aria-hidden='true'></i></button>`,
      )
      .datepicker({
        format: 'dd/mm/yyyy',
        autoHide: true,
        trigger: `#trigger_${el.id}`,
      });
  });

  const $carouselItems = $('.carousel-item');

  if ($carouselItems.length < 2) {
    // if there are no carousels, or there's only one item in the carousel,
    // remove the navigation that's baked in to the template
    $('.carousel--navigation').remove();
  } else {
    const $carouselNav = $('.carousel--navigation');
    const next = $carouselNav.find('.carousel--navigation-arrow-next');
    const prev = $carouselNav.find('.carousel--navigation-arrow-previous');

    next.on('click', ({ currentTarget }) => {
      $(currentTarget)
        .parents('.carousel--wrapper')
        .find('.slick-slider')
        .slick('slickNext');
    });
    prev.on('click', ({ currentTarget }) => {
      $(currentTarget)
        .parents('.carousel--wrapper')
        .find('.slick-slider')
        .slick('slickPrev');
    });
  }

  $carouselItems.each(({ currentTarget }) => {
    const link = $(currentTarget).attr('data-item-link');
    if (link !== '') {
      $(currentTarget).css('cursor', 'pointer');
    }
  });

  $carouselItems.click(({ currentTarget }) => {
    const link = $(currentTarget).attr('data-item-link');
    const exlink = $(currentTarget).attr('data-ex-link');

    if (link !== '') {
      if (exlink === '1') {
        window.open(link, '_blank');
      } else {
        window.location.href = link;
      }
    }
  });

  $('select').on('change', ({ currentTarget }) => {
    $(currentTarget).blur();
  });

  $('.accordion-content').on('show.bs.collapse', ({ currentTarget }) => {
    $(currentTarget).parents('.card').addClass('active');
    $('html, body').animate({
      scrollTop: $('#accordion').offset().top - 100,
    });
  });

  $('.accordion-content').on('hide.bs.collapse', ({ currentTarget }) => {
    $(currentTarget).parents('.card').removeClass('active');
  });

  // console.log('sm2: ', $('.nav-item-top.has-submenu .submenu-two--item'));
  $('.nav-item-top.has-submenu').accessibleMegaMenu({
    /* prefix for generated unique id attributes, which are required
        to indicate aria-owns, aria-controls and aria-labelledby */
    uuidPrefix: 'accessible-megamenu',
    /* css class used to define the megamenu styling */
    menuClass: 'hci--nav-menu',
    /* css class for a top-level navigation item in the megamenu */
    topNavItemClass: 'hci--nav-item',
    /* css class for a megamenu panel */
    panelClass: 'submenu-three',
    // panelClass: 'submenu-three.sub-nav',
    /* css class for a group of items within a megamenu panel */
    panelGroupClass: 'hci--sub-nav-group',
    /* css class for the hover state */
    hoverClass: 'hover',
    /* css class for the focus state */
    focusClass: 'focus',
    /* css class for the open state */
    openClass: 'open',
  });
});

/*-----------------------------------------------------------------
-----------------------------------------------------------------
----------------------- GLOBAL VARIABLES ------------------------
-----------------------------------------------------------------
-----------------------------------------------------------------*/

// window.addEventListener('DOMContentLoaded', () => {
//   document.getElementById('docLoader').style.display = 'block';
//   document.getElementById('sliderLink').style.display = 'block';
//   document.getElementById('sliderNav').style.display = 'block';
// });
