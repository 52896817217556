/* eslint-disable-next-line import/prefer-default-export */
export const sliders = Object({
  topBanner: {
    selector: '.carousel',
    config: {
      slidesToShow: 1,
      accessibility: true,
      focusOnChange: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 6000,
      swipeToSlide: true,
      arrows: false,
      dots: true,
      appendDots: $('.carousel--dots'),
      /*
        you can reference the slider being instantiated
        as slider.$slider
      */
      customPaging: (slider, pageIndex) => $('<button></button').text(`Go to slide ${pageIndex + 1}`),
    },
  },
});
